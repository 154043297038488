import { Link, NavLink, useNavigate } from "react-router-dom";
import "./navbar.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout, setCredentials } from "../../redux/slices/authSlice";
import { useLogoutMutation } from "../../redux/slices/usersApiSlice";
import { toast } from "react-toastify";
import axios from "axios";

const Navbar = () => {
  const [navMobile, setNavMobile] = useState(false);

  return (
    <nav>
      <NavbarDesktop navMobile={navMobile} setNavMobile={setNavMobile} />
      <NavbarMobile navMobile={navMobile} />
    </nav>
  );
};
function NavbarDesktop({ navMobile, setNavMobile }) {
  const { userInfo } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [adminAccount, setAdminAccount] = useState(null);

  useEffect(() => {
    const getAdminAccount = async () => {
      try {
        const response = await axios.get(
          `/admin/${localStorage.getItem("adminName")}`
        );
        setAdminAccount(response?.data);
      } catch (error) {
        toast.error("حدث خطا اثناء التحميل..!");
      }
    };

    getAdminAccount();
  }, []);

  const [logoutApiCall] = useLogoutMutation();

  const logoutHandler = async (e) => {
    e.preventDefault();
    try {
      await logoutApiCall().unwrap();
      dispatch(logout());
      navigate("/");
    } catch (error) {
      toast.error("حدث خطا..!");
      console.log(error);
    }
  };

  const handleLogoutAdmin = () => {
    localStorage.removeItem("adminName");
    window.location.reload();
  };

  return (
    <>
      <div className="container">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
          }}
        >
          <span className="menu-icon" onClick={() => setNavMobile(!navMobile)}>
            <i className="uil uil-bars"></i>
          </span>
          <Link to="/">
            <div className="logo">
              <img src="/assets/logo.png" alt="امين الغازي" />
              <span>الأمين</span>
            </div>
          </Link>
        </div>

        <ul>
          <li>
            <NavLink to={"/"}>الرئيسية</NavLink>
          </li>
          <li>
            <NavLink to={"/features"}>خدمات الطلاب</NavLink>
          </li>
          <li>
            <NavLink to={"/parent-login"}>خدمات ولي الأمر</NavLink>
          </li>
          <li>
            <NavLink to={"/media"}>الاعلانات</NavLink>
          </li>
          <li>
            <NavLink to={"/reviews"}>الآراء</NavLink>
          </li>
          <li>
            <NavLink to={"/add-review"}>قيمنا</NavLink>
          </li>
        </ul>
        <div className="register-btn">
          {userInfo ? (
            <>
              <Link to={"/account"} className="account-link">
                <span>{userInfo.name}</span>
                <i className="uil uil-user "></i>
              </Link>
              <button
                className="btns"
                style={{
                  width: "45px",
                  fontSize: "22px",
                  height: "38px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
              >
                <i class="uil uil-signout" onClick={logoutHandler}></i>
              </button>
            </>
          ) : (
            <>
              <Link to={"/login"} className="btns" style={{ display: "none" }}>
                تسجيل
              </Link>

              {localStorage.getItem("adminName") && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Link
                    to={
                      adminAccount?.isAdmin === true
                        ? "/dashboard/all-data"
                        : "/dashboard/add-video"
                    }
                    className="admin-info"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "2px",
                      color: "var(--main-color)",
                    }}
                  >
                    <span>{localStorage.getItem("adminName")}</span>
                    <i class="uil uil-setting"></i>
                  </Link>
                  <button
                    onClick={handleLogoutAdmin}
                    className="btns"
                    style={{
                      width: "fit-content",
                      fontSize: "22px",
                      height: "44px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                  >
                    <i class="uil uil-signout"></i>
                  </button>
                </div>
              )}
              {!localStorage.getItem("adminName") && (
                <Link
                  to={"/admin-login"}
                  className="btns"
                  style={{
                    width: "60px",
                    padding: "0",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "26px",
                  }}
                >
                  <i class="uil uil-setting"></i>
                </Link>
              )}

              <Link to={"/check-choose"} className="btns">
                حجز
              </Link>
            </>
          )}
        </div>
      </div>
    </>
  );
}
function NavbarMobile({ navMobile }) {
  return (
    <div className={`navbar-mobile ${navMobile ? "active" : ""} `}>
      <ul>
        <li>
          <NavLink to={"/"}>الرئيسية</NavLink>
        </li>
        <li>
          <NavLink to={"/features"}>خدمات الطلاب</NavLink>
        </li>
        <li>
          <NavLink to={"/parent-login"}>خدمات ولي الأمر</NavLink>
        </li>
        <li>
          <NavLink to={"/media"}>الاعلانات</NavLink>
        </li>
        <li>
          <NavLink to={"/reviews"}>الآراء</NavLink>
        </li>
        <li>
          <NavLink to={"/add-review"}>قيمنا</NavLink>
        </li>
      </ul>
    </div>
  );
}
export default Navbar;
