import { Link, NavLink } from "react-router-dom";
import "./dashboard.css";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";

const Sidebar = () => {
  const [account, setAccount] = useState(null);

  useEffect(() => {
    const getAdminAccount = async () => {
      try {
        const response = await axios.get(
          `/admin/${localStorage.getItem("adminName")}`
        );
        setAccount(response?.data);
      } catch (error) {
        toast.error("حدث خطا اثناء التحميل..!");
      }
    };

    getAdminAccount();
  }, []);

  return (
    <div className="sidebar">
      <ul className="ul-links">
        {account?.isAdmin === true && (
          <>
            <NavLink className="ul-link" to={"/dashboard/add-exam"}>
              <i className="uil uil-clipboard-alt"></i>
              <Link to={"/dashboard/add-exam"}>اضافة امتحان</Link>
            </NavLink>
            <NavLink className="ul-link" to={"/dashboard/add-sheet"}>
              <i className="uil uil-clipboard-alt"></i>
              <Link to={"/dashboard/add-sheet"}>اضافة شيت</Link>
            </NavLink>
            <NavLink className="ul-link" to={"/dashboard/add-board"}>
              <i className="uil uil-clipboard"></i>
              <Link to={"/dashboard/add-board"}>اضافة سبورة</Link>
            </NavLink>
          </>
        )}
        <NavLink className="ul-link" to={"/dashboard/add-video"}>
          <i className="uil uil-video"></i>
          <Link to={"/dashboard/add-video"}>اضافة فيديو</Link>
        </NavLink>
        {account?.isAdmin === false && (
          <NavLink className="ul-link" to={"/school-videos"}>
            <i className="uil uil-video"></i>
            <Link to={"/school-videos"}>جدول الفيديوهات</Link>
          </NavLink>
        )}
        {account?.isAdmin === true && (
          <>
            <NavLink className="ul-link" to={"/dashboard/add-audio"}>
              <i className="uil uil-record-audio"></i>
              <Link to={"/dashboard/add-audio"}>اضافة تسجيل</Link>
            </NavLink>
            <NavLink className="ul-link" to={"/dashboard/add-book"}>
              <i className="uil uil-books"></i>
              <Link to={"/dashboard/add-book"}>اضافة مذكرة</Link>
            </NavLink>
            <NavLink className="ul-link" to={"/dashboard/all-students"}>
              <i class="uil uil-user-plus"></i>
              <Link to={"/dashboard/all-students"}>اضافة الطلاب</Link>
            </NavLink>
            <NavLink className="ul-link" to={"/dashboard/all-degrees"}>
              <i className="uil uil-folder-open"></i>
              <Link to={"/dashboard/all-degrees"}>درجات الطلاب</Link>
            </NavLink>
            <NavLink className="ul-link" to={"/dashboard/add-quiz"}>
              <i className="uil uil-folder-open"></i>
              <Link to={"/dashboard/add-quiz"}>اضافة اختبار الكتروني</Link>
            </NavLink>
            <NavLink className="ul-link" to={"/dashboard/add-media"}>
              <i className="uil uil-folder-open"></i>
              <Link to={"/dashboard/add-media"}>اضافة اعلان</Link>
            </NavLink>
            <NavLink className="ul-link" to={"/school-reviews"}>
              <i className="uil uil-folder-open"></i>
              <Link to={"/school-reviews"}>تقييمات الطلاب</Link>
            </NavLink>
            <NavLink className="ul-link" to={"/dashboard/all-data"}>
              <i className="uil uil-folder-open"></i>
              <Link to={"/dashboard/all-data"}>جميع البيانات</Link>
            </NavLink>
          </>
        )}
      </ul>
    </div>
  );
};

export default Sidebar;
